import Link from "components/Link"
import LogoSvg from "components/Header/LogoSvg"

function Logo() {
  return (
    <Link href="/" className="ttnc-logo inline-block text-primary-6000 flex-shrink-0">
      <LogoSvg />
    </Link>
  )
}

export default Logo
