import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  articleEdit: null,
}

const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    setArticle: (state, action) => {
      state.articleEdit = action.payload
    },
    clearArticle: state => {
      state.articleEdit = null
    },
  },
})

export const { setArticle, clearArticle } = articleSlice.actions

export default articleSlice.reducer
