import { axiosBase } from "api/aBase"
import { ObjectToQuery } from "tools/tUri"

export default function useArticleApi() {
  async function saveArticle(data) {
    try {
      const response = await axiosBase.put("article/save", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  async function deleteArticle(data) {
    try {
      const response = await axiosBase.delete("article/delete", data)
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  async function reorderArticles(data) {
    try {
      const response = await axiosBase.put("article/reorder", data)
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  async function getArticles(data) {
    try {
      var query = ObjectToQuery(data)
      const response = await axiosBase.get("article/articles" + query)
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  async function getAllArticles() {
    try {
      const response = await axiosBase.get("article/all-articles")
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  async function getArticleForEdit(data) {
    try {
      var query = ObjectToQuery(data)
      const response = await axiosBase.get("article/get-article-for-edit" + query)
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  return {
    saveArticle,
    deleteArticle,
    reorderArticles,
    getArticles,
    getAllArticles,
    getArticleForEdit,
  }
}
