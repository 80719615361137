import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import ButtonClose from "components/Button/ButtonClose"

function Modal({ isOpen, onClose, title, children, actions }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-75"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50 dark:bg-opacity-80" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-75"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white border border-black border-opacity-5 shadow-xl rounded-2xl dark:bg-neutral-800 dark:border-neutral-700 text-neutral-900 dark:text-neutral-300">
              <ButtonClose
                onClick={onClose}
                className="absolute top-0 right-0 p-2 m-2"
                iconSize="w-8 h-8"
              />
              <Dialog.Title
                as="h3"
                className="py-4 text-xl font-semibold text-center border-b border-neutral-100 dark:border-neutral-700 md:py-5 text-neutral-900 lg:text-2xl dark:text-neutral-200 mx-10"
              >
                {title}
              </Dialog.Title>
              <div className="mt-4">{children}</div>
              <div className="flex justify-end mt-6 space-x-4">{actions}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
