export function ObjectToQuery(obj) {
  if (obj) {
    return Object.entries(obj)
      .filter(([name, value]) => value !== null)
      .map(([name, value], index) => (index === 0 ? `?${name}=${value}` : `&${name}=${value}`))
      .join("")
  }

  return ""
}
