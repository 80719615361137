import { axiosBase } from "api/aBase"
import Button from "components/Button/Button"
import NcImage from "components/Header/NcImage"
import Modal from "components/Modal"
import { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { clearArticle, setArticle as setArticleStore } from "store/articleSlice"
import DashboardPage from "./DashboardPage"

function DashboardPostListPage() {
  const [articles, setArticles] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  // For delete
  const [isOpen, setIsOpen] = useState(false)
  const [articleTitle, setArticleTitle] = useState("")
  const [articleId, setArticleId] = useState(null)

  const getAllArticles = () => {
    axiosBase
      .get("/article/all-articles")
      .then(x => {
        console.log("Response: ", x)
        setArticles(x.data.data)
      })
      .catch(x => {
        console.log("Error: ", x)
      })
  }

  useEffect(() => {
    dispatch(clearArticle())
    getAllArticles()
  }, [])

  const onDragEnd = result => {
    const { source, destination } = result
    if (!destination) {
      return
    }
    if (source.index === destination.index) {
      return
    }
    const newArticles = Array.from(articles)
    const [removed] = newArticles.splice(source.index, 1)
    newArticles.splice(destination.index, 0, removed)
    setArticles(newArticles)
    console.log(newArticles.map(x => x.id))

    axiosBase
      .put("/article/reorder", { ids: newArticles.map(x => x.id) })
      .then(x => {
        console.log(x)
      })
      .catch(x => {
        console.log(x)
      })
  }

  const onEditClick = id => {
    axiosBase
      .get(`/article/get-article-for-edit?id=${id}`)
      .then(x => {
        console.log(x)
        dispatch(setArticleStore(x.data.data))
        navigate("/dashboard/cuvanje-posta")
      })
      .catch(x => {
        console.log(x)
      })
  }

  const onDeleteClick = x => {
    setArticleTitle(x.title)
    setArticleId(x.id)
    setIsOpen(true)
  }

  const deleteArticle = () => {
    axiosBase
      .delete("/article/delete", {
        headers: { "Content-Type": "application/json" },
        data: { id: articleId },
      })
      .then(x => {
        setIsOpen(false)
        setArticleTitle("")
        setArticleId(null)
        getAllArticles()
      })
      .catch(x => {
        console.log(x)
      })
  }

  return (
    <>
      <DashboardPage>
        <div className="flex flex-col space-y-8">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full px-1 sm:px-6 lg:px-8">
              <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="articles">
                    {provided => (
                      <table
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800"
                      >
                        <thead className="bg-neutral-50 dark:bg-neutral-800">
                          <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
                            <th scope="col" className="px-6 py-3">
                              {t("Post")}
                            </th>
                            <th scope="col" className="px-6 py-3">
                              {t("Visible")}
                            </th>
                            <th scope="col" className="px-6 py-3">
                              {t("ShortDesc")}
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                              <span className="sr-only">{t("Edit")}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
                          {articles.map((x, index) => (
                            <Draggable key={x.id} draggableId={x.id.toString()} index={index}>
                              {provided => (
                                <tr
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <td className="px-6 py-4">
                                    <div className="flex items-center w-96 lg:w-auto max-w-md overflow-hidden">
                                      <NcImage
                                        containerClassName="flex-shrink-0 h-12 w-12 rounded-lg relative z-0 overflow-hidden lg:h-14 lg:w-14"
                                        src={x.imageUrl}
                                        fill
                                        sizes="100px"
                                        alt="post"
                                      />
                                      <div className="ml-4 flex-grow">
                                        <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                                          {x.title}
                                        </h2>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {x.isActive ? (
                                      <span className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-teal-100 text-teal-900 lg:text-sm">
                                        {t("Visible_Label")}
                                      </span>
                                    ) : (
                                      <span className="px-2 inline-flex text-sm text-neutral-500 dark:text-neutral-400 rounded-full">
                                        {t("Invisible_Label")}
                                      </span>
                                    )}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-neutral-500 dark:text-neutral-400">
                                    <span>
                                      {x.shortDescription?.length > 35
                                        ? `${x.shortDescription?.slice(0, 35)}...`
                                        : x.shortDescription}
                                    </span>
                                  </td>

                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-neutral-300">
                                    <button
                                      onClick={() => onEditClick(x.id)}
                                      className="text-primary-800 dark:text-primary-500 hover:text-primary-900"
                                    >
                                      {t("Edit")}
                                    </button>
                                    {` | `}
                                    <button
                                      onClick={() => onDeleteClick(x)}
                                      className="text-rose-600 hover:text-rose-900"
                                    >
                                      {t("Delete")}
                                    </button>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      </table>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </DashboardPage>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={articleTitle}
        children={<p>{t("DeletePost_Question")}</p>}
        actions={
          <>
            <Button onClick={deleteArticle} pattern="secondary">
              {t("Delete")}
            </Button>
          </>
        }
      />
    </>
  )
}

export default DashboardPostListPage
