import useArticleApi from "api/aArticle"
import Button from "components/Button/Button"
import Checkbox from "components/Form/Checkbox"
import Input from "components/Form/Input"
import Loading from "components/Loading"
import { useFormik } from "formik"
import { useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { setArticle as setArticleStore } from "store/articleSlice"
import * as Yup from "yup"
import DashboardPage from "./DashboardPage"

function DashboardSaveArticle() {
  const dispatch = useDispatch()
  const api = useArticleApi()
  const { user } = useSelector(x => x.auth)
  const { t } = useTranslation()

  const { articleEdit } = useSelector(x => x.article)

  const [article, setArticle] = useState({
    id: null,
    title: "",
    shortDescription: "",
    image: null,
    userId: user?.id,
    isActive: true,
  })

  console.log({ articleEdit })
  console.log({ article })
  console.log({ user })

  const [loading, setLoading] = useState(false)

  const [responseMessage, setResponseMessage] = useState({
    type: "",
    value: "",
  })

  const inputRef = useRef(null)

  useEffect(() => {
    if (!articleEdit) {
      return
    }

    setArticle(articleEdit)
  }, [articleEdit])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: article.title,
      shortDescription: article.shortDescription,
      image: null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("Post_Title_Required")),
      shortDescription: Yup.string().required(t("Post_ShortDesc_Required")),
    }),
    onSubmit: async values => {
      setResponseMessage({ type: "", value: "" })
      setLoading(true)

      const { title, shortDescription, image } = values

      try {
        const formData = new FormData()
        articleEdit && formData.append("id", +articleEdit.id)
        formData.append("title", title)
        formData.append("shortDescription", shortDescription)
        formData.append("userId", article?.userId ?? user?.id)
        formData.append("isActive", article.isActive)
        if (image) {
          formData.append("image", image)
        }

        const response = await api.saveArticle(formData)

        const { message, succeeded } = response

        if (succeeded === true) {
          dispatch(setArticleStore(response.data))
          setResponseMessage({ type: "success", value: t(message) })
        } else {
          setResponseMessage({ type: "error", value: t(message) })
        }
      } catch (error) {
        setResponseMessage({ type: "error", value: t("Server_Error") })
      } finally {
        setLoading(false)
      }
    },
  })

  const handleFileChange = event => {
    const file = event.target.files[0]
    if (file) {
      validation.setFieldValue("image", file)
    }
  }

  const handleClick = () => {
    inputRef.current.click()
  }

  return (
    <DashboardPage>
      <div className="space-y-6">
        <form onSubmit={validation.handleSubmit} className="grid grid-cols-1 gap-6">
          <ValidationInput validation={validation} label={t("Post_Title")} property="title" />
          <ValidationInput
            validation={validation}
            label={t("Post_ShortDesc")}
            property="shortDescription"
          />

          <div className="relative">
            <input
              id="image-text"
              type="text"
              readOnly
              value={validation.values.image?.name ?? t("Post_FileUrl_Required")}
              onClick={handleClick}
              style={{ paddingLeft: "130px" }}
              className={`block w-full text-base font-normal h-11 px-4 py-3 rounded-lg cursor-pointer bg-white border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200/50 dark:border-neutral-500 dark:focus:ring-primary-500/30 dark:bg-neutral-900 ${
                !validation.values.image?.name && "text-neutral-400"
              }`}
            />
            <button
              type="button"
              id="image-button"
              onClick={handleClick}
              className="absolute inset-y-0 left-0 whitespace-nowrap text-center text-base font-normal px-4 py-3 rounded-lg rounded-r-none cursor-pointer bg-neutral-200 dark:bg-neutral-500"
            >
              {t("Post_SelectFile")}
            </button>
            <input
              id="image-file"
              type="file"
              ref={inputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>

          <Checkbox
            name="isActive"
            label={t("Post_IsActive")}
            isRounded
            checked={article.isActive}
            onChange={() => setArticle(prev => ({ ...prev, isActive: !prev.isActive }))}
          />

          <Button type="submit" pattern={articleEdit?.id ? "secondary" : "primary"}>
            {loading && <Loading />}
            {articleEdit?.id ? t("Post_Update") : t("Post_Create")}
          </Button>
        </form>

        <div
          className={`mx-auto my-4 px-4 py-2 rounded ${
            responseMessage.type === "success"
              ? "bg-green-100 text-green-800"
              : responseMessage.type === "error"
              ? "bg-red-100 text-red-800"
              : responseMessage.type === "warning"
              ? "bg-yellow-100 text-yellow-800"
              : ""
          }`}
        >
          {responseMessage.value}
        </div>
      </div>
    </DashboardPage>
  )
}

function ValidationInput({ property, label, validation, type, placeholder = "" }) {
  return (
    <div className="block">
      <label htmlFor={property} className="text-neutral-800 dark:text-neutral-200 text-sm">
        {label}
      </label>

      <Input
        id={property}
        type={type ?? "text"}
        placeholder={placeholder}
        {...validation.getFieldProps(property)}
      />

      {validation.touched[property] && validation.errors[property] ? (
        <div className="text-sm text-red-600">{validation.errors[property]}</div>
      ) : null}
    </div>
  )
}

export default DashboardSaveArticle
