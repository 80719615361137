import Heading from "components/Heading"
import Link from "components/Link"
import usePathname from "hooks/usePathname"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { clearUser as clearUserStore } from "store/authSlice"

function DashboardPage({ children }) {
  const pathname = usePathname()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const subPages = [
    {
      href: "/dashboard/postovi",
      emoij: "📕",
      pageName: t("Posts"),
    },
    {
      href: "/dashboard/cuvanje-posta",

      emoij: "✍",
      pageName: t("SavePost"),
    },
  ]

  const signOut = () => {
    dispatch(clearUserStore())
    navigate("/")
  }

  return (
    <div className={`nc-LayoutPage relative`}>
      <div
        className={`absolute h-[400px] top-0 left-0 right-0 w-full bg-primary-100 dark:bg-neutral-800 bg-opacity-25 dark:bg-opacity-40`}
      />
      <div className="container relative pt-6 sm:pt-10 pb-16 lg:pt-20 lg:pb-28">
        {/* CONTENT */}
        <div className="p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-neutral-900">
          <div className={`nc-PageDashboard`}>
            <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-24">
              <Heading emoji="">{t("Dashboard")}</Heading>
              <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                {t("DashboardDesc")}
              </span>
            </header>

            <div className="flex flex-col space-y-8 xl:space-y-0 xl:flex-row">
              {/* SIDEBAR */}

              <div className="flex-shrink-0 max-w-xl xl:w-80 xl:pr-8">
                <ul className="text-base space-y-1 text-neutral-700 dark:text-neutral-400">
                  {subPages.map(({ href, pageName, emoij }, index) => {
                    return (
                      <li key={index}>
                        <Link
                          className={`px-6 py-3 font-medium rounded-full flex items-center ${
                            pathname === href
                              ? "bg-neutral-100 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-100"
                              : "hover:text-neutral-800 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-100"
                          }`}
                          href={href}
                        >
                          <span className="w-8 mr-2 text-lg">{emoij}</span>
                          <span> {pageName}</span>
                        </Link>
                      </li>
                    )
                  })}

                  <li className=" border-t border-neutral-200 dark:border-neutral-700" />
                  <li>
                    <Link
                      className={`flex items-center px-6 py-3 font-medium text-red-500`}
                      onClick={signOut}
                    >
                      <span className="w-8 mr-2 text-lg">💡</span>
                      {t("SignOut")}
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="border-t border-neutral-500 dark:border-neutral-300 md:hidden"></div>

              <div className="flex-1">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardPage
