import AvatarDropdown from "components/AvatarDropdown/AvatarDropdown"
import NavigationItem from "components/Header/NavigationItem"
import LanguageDropdown from "components/LanguageDropdown/LanguageDropdown"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const randomId = _.uniqueId

function Navigation({ className = "flex" }) {
  const { user } = useSelector(x => x.auth)
  const { t } = useTranslation()

  return (
    <ul className={`nc-Navigation items-center ${className}`}>
      {[
        {
          id: randomId(),
          href: "/",
          name: t("Page_Home"),
        },

        {
          id: randomId(),
          href: "/galerija",
          name: t("Page_History"),
        },

        {
          id: randomId(),
          href: "/kontakt",
          name: t("Page_ContactUs"),
        },
      ].map(item => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}

      <LanguageDropdown />
      {user && <AvatarDropdown />}
    </ul>
  )
}

export default Navigation
