import AvatarDropdown from "components/AvatarDropdown/AvatarDropdown"
import Logo from "components/Header/Logo"
import MenuBar from "components/Header/MenuBar"
import Navigation from "components/Header/Navigation"
import LanguageDropdown from "components/LanguageDropdown/LanguageDropdown"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

function Header() {
  const { t } = useTranslation()
  const { user } = useSelector(x => x.auth)

  return (
    <div className="nc-Header sticky top-0 w-full z-40">
      <div className="nc-MainNav1 relative z-10 bg-white dark:bg-neutral-900">
        <div className="container">
          <div className="h-20 py-5 flex justify-between items-center">
            <div className="flex items-center lg:hidden flex-1">
              <MenuBar />
            </div>

            <div className="hidden lg:flex justify-center lg:justify-between flex-1 items-center space-x-4 sm:space-x-10 2xl:space-x-14">
              <div className="flex items-center mb-4 lg:mb-0">
                <div className="flex ttnc-logo text-primary-6000 flex-shrink-0 mr-3">
                  <Logo />
                </div>
                <span className="text-xl font-bold font-grenze">{t("Common.Title")}</span>
              </div>
              <Navigation className="hidden lg:flex" />
            </div>

            <div className="flex lg:hidden">
              <Logo />
            </div>

            <div className="md:flex lg:hidden flex-1 items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
              <div className="hidden items-center lg:flex"></div>
              <div className="flex items-end justify-end lg:hidden">
                <LanguageDropdown />
                {user && <AvatarDropdown />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
