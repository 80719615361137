import { XMarkIcon } from "@heroicons/react/24/solid"
import { useTranslation } from "react-i18next"
import twFocusClass from "tools/twFocusClass"

function ButtonClose({
  className = "",
  onClick = () => {},
  iconSize = "w-5 h-5",
  buttonSize = "w-8 h-8",
}) {
  const { t } = useTranslation()

  return (
    <button
      className={
        `${buttonSize} flex items-center justify-center rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-700 ${className} ` +
        twFocusClass()
      }
      onClick={onClick}
    >
      <span className="sr-only">{t("Close")}</span>
      <XMarkIcon className={iconSize} />
    </button>
  )
}

export default ButtonClose
