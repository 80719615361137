import FeaturedIcon from "components/FeaturedMedia/FeaturedIcon"
import MediaVideo from "components/FeaturedMedia/MediaVideo"
import Image from "components/Image"
import Link from "components/Link"

function FeaturedMedia({ className = "w-full h-full", article, isHover = false }) {
  const { imageUrl } = article

  const fileIsVideo = false

  const isPostMedia = () => fileIsVideo === true

  const renderContent = () => {
    // VIDEO
    if (fileIsVideo === true && !!imageUrl && isHover) {
      return <MediaVideo isHover videoUrl={imageUrl} />
    }

    // ICON
    return isPostMedia() ? (
      <span className="absolute inset-0 flex items-center justify-center ">
        <FeaturedIcon
          className="hover:scale-105 transform cursor-pointer transition-transform"
          isVideo={fileIsVideo === true}
        />
      </span>
    ) : null
  }

  return (
    <div className={`nc-PostFeaturedMedia relative ${className}`}>
      {/* {postType !== "gallery" && ( */}
      <Image
        alt="featured"
        fill
        className="object-cover"
        src={imageUrl}
        sizes="(max-width: 600px) 480px, 800px"
      />
      {/* )} */}
      {renderContent()}
      {/* {postType !== "gallery" && ( */}
      <Link
        href={"/"}
        className={`block absolute inset-0 ${
          !fileIsVideo ? "bg-black/20 transition-opacity opacity-0 group-hover:opacity-100" : ""
        }`}
      />
      {/* )} */}
    </div>
  )
}

export default FeaturedMedia
