function Image({ fill = false, className = "", alt = "", style, onLoadingComplete, onClick, ...args }) {
  return (
    <img
      {...args}
      className={className + (fill ? " object-cover absolute inset-0 w-full h-full" : "")}
      alt={alt}
      style={style}
      onLoad={onLoadingComplete}
      onClick={onClick}
    />
  )
}

export default Image
