import "assets/styles/index.scss"
import ModalProvider from "providers/ModalProvider"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import store from "store/store"
import App from "./App"
import "./index.css"
import "./i18n"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    <ModalProvider>
      <App />
    </ModalProvider>
  </Provider>
)
