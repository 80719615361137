import axios from "axios"
import { axiosBase } from "api/aBase"

export default function useAuthApi() {
  async function signUp(data) {
    try {
      const response = await axiosBase.post("auth/sign-up", data)
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  async function activateUser(data) {
    try {
      const response = await axiosBase.post("auth/activate", data)
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  async function signIn(data) {
    try {
      const response = await axiosBase.post("auth/sign-in", data)
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  async function checkToken(cancelToken) {
    try {
      const response = await axiosBase.get("auth/check-token", { cancelToken })
      return response.data
    } catch (err) {
      if (!axios.isCancel(err)) {
        return err
      }
    }
  }

  async function changePassword(data) {
    try {
      const response = await axiosBase.post("auth/change-password", data)
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  async function forgotPassword(data) {
    try {
      const response = await axiosBase.post("auth/forgot-password", data)
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  async function resetPassword(data) {
    try {
      const response = await axiosBase.post("auth/reset-password", data)
      return response.data
    } catch (err) {
      return err.response.data
    }
  }

  return {
    signUp,
    activateUser,
    signIn,
    checkToken,
    changePassword,
    forgotPassword,
    resetPassword,
  }
}
