import { Fragment, useState } from "react"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { Popover, Transition } from "@headlessui/react"
import Link from "components/Link"
import NcImage from "components/Header/NcImage"
import { RECENT_POSTS } from "common/data/navigation"

function NavigationItem({ menuItem }) {
  const [menuCurrentHovers, setMenuCurrentHovers] = useState([])

  const onMouseEnterMenu = id => {
    setMenuCurrentHovers(state => [...state, id])
  }

  const onMouseLeaveMenu = id => {
    setMenuCurrentHovers(state => {
      return state.filter((item, index) => {
        return item !== id && index < state.indexOf(id)
      })
    })
  }

  const renderMegaMenu = menu => {
    if (!menu.children) {
      return null
    }

    return (
      <li className={`menu-item flex-shrink-0 menu-megamenu menu-megamenu--large`}>
        {renderMainItem(menu)}

        <div className="invisible sub-menu absolute top-full inset-x-0 transform z-50">
          <div className="bg-white dark:bg-neutral-900 shadow-lg">
            <div className="container">
              <div className="flex text-sm border-t border-slate-200 dark:border-slate-700 py-14">
                <div className="flex-1 grid grid-cols-4 gap-6 pr-6 xl:pr-8">
                  {menu.children.map((item, index) => (
                    <div key={index}>
                      <p className="font-medium text-slate-900 dark:text-neutral-200">
                        {item.name}
                      </p>
                      <ul className="grid space-y-4 mt-4">
                        {item.children?.map(renderMegaMenuNavlink)}
                      </ul>
                    </div>
                  ))}
                </div>
                <div className="w-[40%] ">
                  <div className="grid grid-cols-1 gap-10 sm:gap-8 lg:grid-cols-2">
                    <h3 className="sr-only">Recent posts</h3>
                    {RECENT_POSTS.map(post => (
                      <article
                        key={post.id}
                        className="relative isolate flex max-w-2xl flex-col gap-x-8 gap-y-6 sm:flex-row sm:items-start lg:flex-col lg:items-stretch"
                      >
                        <div className="relative flex-none">
                          <NcImage
                            containerClassName="aspect-[2/1] w-full rounded-xl bg-gray-100 sm:aspect-[16/9] sm:h-32 lg:h-auto z-0"
                            fill
                            className="rounded-xl object-cover"
                            src={post.imageUrl}
                            sizes="300px"
                            alt=""
                          />
                          <div className="absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div>
                          <div className="flex items-center gap-x-4">
                            <time
                              dateTime={post.datetime}
                              className="text-sm leading-6 text-gray-600"
                            >
                              {post.date}
                            </time>
                            <Link
                              href={post.category.href}
                              className="relative z-10 rounded-full bg-gray-50 py-1.5 px-3 text-xs font-medium text-gray-600 hover:bg-gray-100"
                            >
                              {post.category.title}
                            </Link>
                          </div>
                          <h4 className="mt-2 text-sm font-semibold leading-6 text-gray-900">
                            <Link href={post.href}>
                              <span className="absolute inset-0" />
                              {post.title}
                            </Link>
                          </h4>
                          <p className="mt-2 text-sm leading-6 text-gray-600">{post.description}</p>
                        </div>
                      </article>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    )
  }

  const renderMegaMenuNavlink = item => {
    return (
      <li key={item.id} className={`${item.isNew ? "menuIsNew" : ""}`}>
        <Link
          className="font-normal text-slate-600 hover:text-black dark:text-slate-400 dark:hover:text-white "
          href={item.href}
        >
          {item.name}
        </Link>
      </li>
    )
  }

  const renderDropdownMenu = menuDropdown => {
    const isHover = menuCurrentHovers.includes(menuDropdown.id)
    return (
      <Popover
        as="li"
        className="menu-item menu-dropdown relative"
        onMouseEnter={() => onMouseEnterMenu(menuDropdown.id)}
        onMouseLeave={() => onMouseLeaveMenu(menuDropdown.id)}
      >
        {() => (
          <>
            <Popover.Button as={Fragment}>{renderMainItem(menuDropdown)}</Popover.Button>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className="sub-menu absolute transform z-10 w-56 top-full left-0"
              >
                <ul className="rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10 text-sm relative bg-white dark:bg-neutral-900 py-4 grid space-y-1">
                  {menuDropdown.children?.map(i => {
                    if (i.type) {
                      return renderDropdownMenuNavlinkHasChild(i)
                    } else {
                      return (
                        <li key={i.id} className={`px-2 ${i.isNew ? "menuIsNew" : ""}`}>
                          {renderDropdownMenuNavlink(i)}
                        </li>
                      )
                    }
                  })}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    )
  }

  const renderDropdownMenuNavlinkHasChild = item => {
    const isHover = menuCurrentHovers.includes(item.id)
    return (
      <Popover
        as="li"
        key={item.id}
        className="menu-item menu-dropdown relative px-2"
        onMouseEnter={() => onMouseEnterMenu(item.id)}
        onMouseLeave={() => onMouseLeaveMenu(item.id)}
      >
        {() => (
          <>
            <Popover.Button as={"p"}>{renderDropdownMenuNavlink(item)}</Popover.Button>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel static className="sub-menu absolute z-10 w-56 left-full pl-2 top-0">
                <ul className="rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10 text-sm relative bg-white dark:bg-neutral-900 py-4 grid space-y-1">
                  {item.children?.map(i => {
                    if (i.type) {
                      return renderDropdownMenuNavlinkHasChild(i)
                    } else {
                      return (
                        <li key={i.id} className="px-2">
                          {renderDropdownMenuNavlink(i)}
                        </li>
                      )
                    }
                  })}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    )
  }

  const renderDropdownMenuNavlink = item => {
    return (
      <Link
        className="flex items-center font-normal text-neutral-6000 dark:text-neutral-200 py-2 px-4 rounded-md hover:text-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200"
        href={item.href}
      >
        {item.name}
        {item.type && (
          <ChevronDownIcon className="ml-2 h-4 w-4 text-neutral-500" aria-hidden="true" />
        )}
      </Link>
    )
  }

  const renderMainItem = item => {
    return (
      <div className="h-20 flex-shrink-0 flex items-center">
        <Link
          className="inline-flex items-center text-sm lg:text-[15px] font-medium text-slate-700 dark:text-slate-300 py-2.5 px-4 xl:px-5 rounded-full hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200"
          href={item.href}
        >
          {item.name}
          {item.type && (
            <ChevronDownIcon className="ml-1 -mr-1 h-4 w-4 text-slate-400" aria-hidden="true" />
          )}
        </Link>
      </div>
    )
  }

  switch (menuItem.type) {
    case "dropdown":
      return renderDropdownMenu(menuItem)
    case "megaMenu":
      return renderMegaMenu(menuItem)
    default:
      return <li className="menu-item flex-shrink-0">{renderMainItem(menuItem)}</li>
  }
}

export default NavigationItem
