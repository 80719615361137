import { createSlice } from "@reduxjs/toolkit"
import { axiosBase } from "api/aBase"
import { ACCESS_TOKEN } from "constants/auth"

const initialState = {
  user: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload

      if (action.payload?.accessToken) {
        localStorage.setItem(ACCESS_TOKEN, action.payload.accessToken)
        axiosBase.defaults.headers["Authorization"] = "Bearer " + action.payload?.accessToken
      }
    },
    clearUser: state => {
      state.user = null
      localStorage.removeItem(ACCESS_TOKEN)
    },
  },
})

export const { setUser, clearUser } = authSlice.actions

export default authSlice.reducer
