import ButtonSecondary from "components/Button/ButtonSecondary"
import { useTranslation } from "react-i18next"

function NotFoundPage() {
  const { t } = useTranslation()

  return (
    <div className="nc-Page404">
      <div className="container relative py-16 lg:py-20">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-7">
          <h2 className="text-7xl md:text-8xl">🪔</h2>
          <h1 className="text-8xl md:text-9xl font-semibold tracking-widest">404</h1>
          <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
            {`Stranica koju tražite ne postoji.`}
          </span>
          <ButtonSecondary href="/" className="mt-4">
            {t("BackHome")}
          </ButtonSecondary>
        </header>
      </div>
    </div>
  )
}

export default NotFoundPage
