import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationEn from "./locales/en.json"
import translationSrL from "./locales/srL.json"
import translationSrC from "./locales/srC.json"

const resources = {
  srL: {
    translation: translationSrL,
  },
  srC: {
    translation: translationSrC,
  },
  en: {
    translation: translationEn,
  },
}

const DEFAULT_LANGUAGE = "srL"
const SAVED_LANGUAGE = localStorage.getItem("language") || DEFAULT_LANGUAGE

i18n.use(initReactI18next).init({
  resources,
  lng: SAVED_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
})

i18n.on("languageChanged", lng => {
  localStorage.setItem("language", lng)
})

export default i18n
