import BackgroundSection from "components/BackgroundSection"
import ButtonSecondary from "components/Button/ButtonSecondary"
import SliderPosts from "components/Slider/SliderPosts"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

function Section2({ articles }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="container relative py-16 my-0 md:my-20">
      <BackgroundSection />

      <SliderPosts
        heading={t("ExploreChetniks")}
        subHeading={t("ExploreHistory")}
        posts={articles}
      />

      <div className="flex justify-center mt-10">
        <ButtonSecondary onClick={() => navigate("/galerija")}>{t("ShowAll")}</ButtonSecondary>
      </div>
    </div>
  )
}

export default Section2
