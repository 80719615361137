import { useState, useEffect } from "react"

const tailwindBreakpoints = {
  width: {
    sm: 640, // Small screen
    md: 768, // Medium screen
    lg: 1024, // Large screen
    xl: 1280, // Extra large screen
    "2xl": 1536, // 2XL screen
  },
  height: {
    sm: 500, // Small screen height (example value)
    md: 600, // Medium screen height (example value)
    lg: 800, // Large screen height (example value)
    xl: 1000, // Extra large screen height (example value)
    "2xl": 1200, // 2XL screen height (example value)
  },
}

const useTailwindBreakpoint = () => {
  const [screenSize, setScreenSize] = useState({
    width: "",
    height: "",
  })
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      const height = window.innerHeight

      setDimensions({ width, height })

      // Width detection
      if (width < tailwindBreakpoints.width.sm) {
        screenSize.width = "xs"
      } else if (width >= tailwindBreakpoints.width.sm && width < tailwindBreakpoints.width.md) {
        screenSize.width = "sm"
      } else if (width >= tailwindBreakpoints.width.md && width < tailwindBreakpoints.width.lg) {
        screenSize.width = "md"
      } else if (width >= tailwindBreakpoints.width.lg && width < tailwindBreakpoints.width.xl) {
        screenSize.width = "lg"
      } else if (
        width >= tailwindBreakpoints.width.xl &&
        width < tailwindBreakpoints.width["2xl"]
      ) {
        screenSize.width = "xl"
      } else {
        screenSize.width = "2xl"
      }

      // Height detection
      if (height < tailwindBreakpoints.height.sm) {
        screenSize.height = "xs"
      } else if (
        height >= tailwindBreakpoints.height.sm &&
        height < tailwindBreakpoints.height.md
      ) {
        screenSize.height = "sm"
      } else if (
        height >= tailwindBreakpoints.height.md &&
        height < tailwindBreakpoints.height.lg
      ) {
        screenSize.height = "md"
      } else if (
        height >= tailwindBreakpoints.height.lg &&
        height < tailwindBreakpoints.height.xl
      ) {
        screenSize.height = "lg"
      } else if (
        height >= tailwindBreakpoints.height.xl &&
        height < tailwindBreakpoints.height["2xl"]
      ) {
        screenSize.height = "xl"
      } else {
        screenSize.height = "2xl"
      }

      setScreenSize({ ...screenSize })
    }

    window.addEventListener("resize", handleResize)
    handleResize() // Call once initially to set the initial screen size

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return { screenSize, dimensions }
}

export default useTailwindBreakpoint
