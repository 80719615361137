import useAuthApi from "api/aAuth"
import ButtonPrimary from "components/Button/ButtonPrimary"
import Input from "components/Form/Input"
import Heading from "components/Heading"
import Loading from "components/Loading"
import NcLink from "components/NcLink"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { setUser as setUserStore } from "store/authSlice"
import * as Yup from "yup"

function SignInPage() {
  const api = useAuthApi()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get("t")

  const [responseMessage, setResponseMessage] = useState({
    type: "",
    value: "",
  })

  const [loading, setLoading] = useState(false) // Add loading state

  useEffect(() => {
    if (!token) {
      return
    }

    api.activateUser({ token: token }).then(x => {
      const { message, succeeded } = x
      setResponseMessage({ type: succeeded ? "success" : "error", value: t(message) })
    })
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t("SignIn_Email_Reg")).required(t("SignIn_Email_Required")),
      password: Yup.string()
        .matches(/^(?=.*[A-Z])(?=.*\d).{7,}$/, t("SignIn_Password_Reg"))
        .required(t("SignIn_Password_Required")),
    }),
    onSubmit: async values => {
      setResponseMessage({ type: "", value: "" })

      // Use async/await to handle loading state
      setLoading(true) // Set loading to true when the form is submitted
      const { email, password } = values

      try {
        const x = await api.signIn({ email, password })
        const { message, succeeded } = x

        if (succeeded === true) {
          dispatch(setUserStore(x.data))
          navigate("/")
          setResponseMessage({ type: "success", value: t(message) })
        } else {
          setResponseMessage({ type: "error", value: t(message) })
        }
      } catch (error) {
        setResponseMessage({ type: "error", value: t("Server_Error") })
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <div className={`nc-LayoutPage relative`}>
      <div
        className={`absolute h-[400px] top-0 left-0 right-0 w-full bg-primary-100 dark:bg-neutral-800 bg-opacity-25 dark:bg-opacity-40`}
      />
      <div className="container relative pt-6 sm:pt-10 pb-16 lg:pt-20 lg:pb-28">
        <div className="p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-neutral-900">
          <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
            <Heading>{t("SignIn")}</Heading>
            <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
              {t("Welcome")}
            </span>
          </header>

          <div className="max-w-md mx-auto space-y-6">
            <form onSubmit={validation.handleSubmit} className="grid grid-cols-1 gap-6">
              <ValidationInput validation={validation} label={t("EmailAddress")} property="email" />

              <ValidationInput
                validation={validation}
                label={t("Password")}
                property="password"
                type="password"
              />

              <ButtonPrimary type="submit">
                {loading && <Loading />}
                {t("SignIn")}
              </ButtonPrimary>
            </form>

            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              {t("NoAccount")} {` `}
              <NcLink href="/registracija">{t("SignUp")}</NcLink>
            </span>

            <div
              className={`mx-auto my-4 px-4 py-2 rounded ${
                responseMessage.type === "success"
                  ? "bg-green-100 text-green-800"
                  : responseMessage.type === "error"
                  ? "bg-red-100 text-red-800"
                  : responseMessage.type === "warning"
                  ? "bg-yellow-100 text-yellow-800"
                  : ""
              }`}
            >
              {responseMessage.value}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ValidationInput({ property, label, validation, type }) {
  return (
    <div className="block">
      <label htmlFor={property} className="text-neutral-800 dark:text-neutral-200">
        {label}
      </label>

      <Input id={property} type={type ?? "text"} {...validation.getFieldProps(property)} />

      {validation.touched[property] && validation.errors[property] ? (
        <div className="text-sm text-red-600">{validation.errors[property]}</div>
      ) : null}
    </div>
  )
}

export default SignInPage
