import FeaturedMedia from "components/FeaturedMedia/FeaturedMedia"
import Link from "components/Link"
import { useState } from "react"

function Card({ className = "h-full", size = "large", article }) {
  const { title, shortDescription } = article

  const [isHover, setIsHover] = useState(false)

  return (
    <div
      className={`nc-Card2 group relative flex flex-col ${className}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="block group rounded-3xl flex-shrink-0 relative w-full aspect-w-3 sm:aspect-w-4 aspect-h-3 sm:aspect-h-3 overflow-hidden z-0">
        <div>
          <FeaturedMedia article={article} isHover={isHover} />
        </div>
      </div>

      <div className="mt-5 px-4 flex flex-col">
        <div className="space-y-3">
          {/* <PostCardMeta
            className="relative text-sm"
            avatarSize="h-8 w-8 text-sm"
            article={article}
          /> */}

          <h2
            className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 ${
              size === "large" ? "text-base sm:text-lg md:text-xl" : "text-base"
            }`}
          >
            <div className="line-clamp-2" title={title}>
              {title}
            </div>
          </h2>
          <span className="block text-neutral-500 dark:text-neutral-400 text-[15px] leading-6 ">
            {shortDescription?.length > 80
              ? `${shortDescription?.slice(0, 80)}...`
              : shortDescription}
          </span>
        </div>
        {/* <div className="my-5 border-t border-neutral-200 dark:border-neutral-700"></div> */}
        <div className="flex items-center justify-between"></div>
      </div>
    </div>
  )
}

export default Card
