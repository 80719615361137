import { axiosBase } from "api/aBase"
import ButtonSecondary from "components/Button/ButtonSecondary"
import Input from "components/Form/Input"
import Textarea from "components/Form/Textarea"
import Image from "components/Image"
import {
  discord_48_Png,
  instagram_48_Png,
  tiktok_48_Png,
  youTube_48_Png,
} from "components/SocialMediaIcon/icons"
import { useFormik } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

function ContactUsPage() {
  const { t } = useTranslation()
  const podaci = [
    {
      title: `💌 ${t("ContactUs_Email_Label")}`,
      desc: "support@mladenbodybuilding.com",
    },
  ]

  const [loading, setLoading] = useState(false)

  const [responseMessage, setResponseMessage] = useState({
    type: "",
    value: "",
  })

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullName: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required(t("ContactUs_FullName_Required")),
      email: Yup.string().email(t("ContactUs_Email_Reg")).required(t("ContactUs_Email_Required")),
      message: Yup.string().required(t("ContactUs_Message_Required")),
    }),
    onSubmit: async values => {
      setResponseMessage({ type: "", value: "" })

      const { fullName, email } = values

      setLoading(true)

      try {
        const x = await axiosBase.post("/user/contact-us", {
          fullName,
          email,
          message: values.message,
        })

        const { message, succeeded } = x.data

        if (succeeded === true) {
          setResponseMessage({ type: "success", value: t(message) })
        } else {
          setResponseMessage({ type: "error", value: t(message) })
        }
      } catch (error) {
        setResponseMessage({ type: "error", value: t("Server_Error") })
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <div className={`nc-LayoutPage relative`}>
      <div
        className={`absolute h-[400px] top-0 left-0 right-0 w-full bg-primary-100 dark:bg-neutral-800 bg-opacity-25 dark:bg-opacity-40`}
      />
      <div className="container relative pt-6 sm:pt-10 pb-16 lg:pt-20 lg:pb-28">
        {/* CONTENT */}
        <div className="p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-neutral-900">
          <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-28 ">
            <Heading>{t("ContactUs")}</Heading>
            <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
              {t("ContactUs_Message")}
            </span>
          </header>

          <div className="grid gap-8 lg:grid-cols-2">
            <div className="max-w-sm space-y-6">
              <div>
                {/* <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 {t("ContactUs_SocialMedia_Label")}
                </h3> */}

                {/* <SocialsList className="mt-2" /> */}
                {/* <div className="flex justify-start gap-x-3 items-center mt-4">
                  <a
                    href={"https://www.instagram.com/chetniksesports/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image src={instagram_48_Png} style={{ width: "30px" }} />
                  </a>

                  <a href={"https://discord.com/invite/35euMVw"} target="_blank" rel="noreferrer">
                    <Image src={discord_48_Png} style={{ width: "30px" }} />
                  </a>

                  <a
                    href={"https://www.youtube.com/channel/UCYqqLWg7r_qZP4fXdf5KtMg"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image src={youTube_48_Png} style={{ width: "30px" }} />
                  </a>

                  <a
                    href={"https://www.tiktok.com/@chetniksesports"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image src={tiktok_48_Png} style={{ width: "30px" }} />
                  </a>
                </div> */}
              </div>

              {podaci.map((x, i) => (
                <div key={i}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {x.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {x.desc}
                  </span>
                </div>
              ))}
            </div>
            <div className="border border-neutral-100 dark:border-neutral-700 lg:hidden"></div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={validation.handleSubmit}>
                <ValidationInput
                  validation={validation}
                  label={t("FullName")}
                  property="fullName"
                />
                <ValidationInput
                  validation={validation}
                  label={t("EmailAddress")}
                  property="email"
                />
                <ValidationInput
                  validation={validation}
                  label={t("Message")}
                  property="message"
                  isTextArea
                />
                <ButtonSecondary loading={loading} type="submit">
                  {t("ContactUs_SendMessage")}
                </ButtonSecondary>
              </form>

              <div
                className={`mx-auto my-4 px-4 py-2 rounded ${
                  responseMessage.type === "success"
                    ? "bg-green-100 text-green-800"
                    : responseMessage.type === "error"
                    ? "bg-red-100 text-red-800"
                    : responseMessage.type === "warning"
                    ? "bg-yellow-100 text-yellow-800"
                    : ""
                }`}
              >
                {responseMessage.value}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Heading({ children, emoji = "", className = "justify-center", ...args }) {
  return (
    <h2
      className={`flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 ${className}`}
      {...args}
    >
      {!!emoji && (
        <span className="mr-4 text-2xl md:text-3xl lg:text-4xl leading-none">{emoji}</span>
      )}
      {children || `Heading2 Title`}
    </h2>
  )
}

function ValidationInput({ property, label, validation, type, isTextArea = false }) {
  return (
    <div className="block">
      <label htmlFor={property} className="text-neutral-800 dark:text-neutral-200">
        {label}
      </label>

      {isTextArea ? (
        <Textarea id={property} className="mt-1" rows={6} {...validation.getFieldProps(property)} />
      ) : (
        <Input id={property} type={type ?? "text"} {...validation.getFieldProps(property)} />
      )}

      {validation.touched[property] && validation.errors[property] ? (
        <div className="text-sm text-red-600">{validation.errors[property]}</div>
      ) : null}
    </div>
  )
}

export default ContactUsPage
